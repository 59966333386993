import SbEditable from "storyblok-react"
import dynamic from "next/dynamic"

// resolve Storyblok components to Next.js components
export const Components = {
  // Atoms
  button: dynamic(() => import("./atoms/StoryblokButton")),
  card: dynamic(() => import("./atoms/Card")),
  campaign_showcase: dynamic(() => import("./atoms/CampaignShowcase")),
  two_column_list: dynamic(() => import("./atoms/TwoColumnList")),

  // Molecules
  section_container: dynamic(() => import("./molecules/SectionContainer")),
  basic_page_header: dynamic(() => import("./molecules/PageHeaderBasicDynamic")),
  benefits_section: dynamic(() => import("./molecules/BenefitsSection")),
  icon_card_section: dynamic(() => import("./molecules/IconCardSection")),
  faq: dynamic(() => import("./molecules/FAQSection")),
  featured_image: dynamic(() => import("./molecules/FeaturedImage")),
  form_section: dynamic(() => import("./molecules/FormSectionDynamic")),
  generic_section: dynamic(() => import("./molecules/GenericSection")),
  image_and_content: dynamic(() => import("./molecules/ImageAndContent")),
  icons_and_content: dynamic(() => import("./molecules/IconsAndContent")),
  list_and_content: dynamic(() => import("./molecules/ListAndContent")),
  page_header: dynamic(() => import("./molecules/PageHeaderFullDynamic")),
  three_column_cards: dynamic(() => import("./molecules/ThreeColumnCardSection")),
  testimonial_section: dynamic(() => import("./molecules/TestimonialSection")),
  cta_banner: dynamic(() => import("./molecules/CTABannerDynamic")),
  sidebar_section: dynamic(() => import("./molecules/SidebarSectionDynamic")),
  yt_embed: dynamic(() => import("./molecules/DynamicVideo")),

  // Organisms
  page: dynamic(() => import("./organisms/Page")),
  contact_page: dynamic(() => import("./organisms/ContactPage")),
}

const DynamicComponent = ({ blok }) => {
  // check if component is defined above
  if (typeof Components[blok?.component] !== "undefined") {
    const Component = Components[blok.component]

    // Set an _editable value to avoid error in SbEditable
    if (blok._editable === null) {
      blok._editable = undefined
    }
    if (typeof window !== "undefined" && window.location.search.includes("_storyblok")) {
      return (
        <SbEditable content={blok}>
          <Component blok={blok} />
        </SbEditable>
      )
    }

    return <Component blok={blok} />
  }

  // fallback if the component doesn't exist
  return null
}

export default DynamicComponent
